import React, { useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import OnlineStatus from "./OnlineStatus";
import { PrimeSearchInput } from "components";
import ConfigureInterfaceColumn from "./ConfigureInterfaceColumn";
import { RefreshButton } from "components/atoms/RefreshButton";
import { customStyles } from "utils/datatableStyles";
import { history } from "utils";
import { useDeleteGateway } from "../hooks/useDeleteGateway";
import { useParams } from "react-router";
import { useDeviceGatewaysDatatable } from "../hooks/useDeviceGatewaysDatatable";
import { useTableColumnConfig } from "../hooks/useTableColumnConfig";
import DeviceGatewayFilter from "./Filter/DeviceGatewayFilter";
import { useDeviceGatewayFilter } from "../hooks/useDeviceGatewayFilter";

const defaultVisibleColumn = [
  "statusTranslated",
  "name",
  "interface",
  "protocol",
  "priority",
  "gateway",
  "monitoring",
  "rtt",
  "rttD",
  "loss",
  "desc",
];

const columnOptions = [
  { className: "text-xs", value: "statusTranslated", label: "Status" },
  { className: "text-xs", value: "name", label: "Name" },
  { className: "text-xs", value: "interface", label: "Interface" },
  { className: "text-xs", value: "protocol", label: "Protocol" },
  { className: "text-xs", value: "priority", label: "Priority" },
  { className: "text-xs", value: "gateway", label: "Gateway" },
  { className: "text-xs", value: "monitoring", label: "Monitoring IP" },
  { className: "text-xs", value: "rtt", label: "RTT" },
  { className: "text-xs", value: "rttD", label: "RTTd" },
  { className: "text-xs", value: "loss", label: "Loss" },
  { className: "text-xs", value: "desc", label: "Description" },
];

const GatewayConfigurationTabPanel = () => {
  const { sn } = useParams();
  const [visibleColumns, setVisibleColumns] = useTableColumnConfig(
    "sdwan-gateway-table-config",
    defaultVisibleColumn
  );
  const deleteGateway = useDeleteGateway(sn);
  const [smartSearch, setSmartSearch] = useState("");
  const { filters } = useDeviceGatewayFilter();
  const dt = useDeviceGatewaysDatatable({
    sn,
    smartSearch: smartSearch && smartSearch !== "" ? smartSearch : undefined,
    name: filters.gw_name ?? undefined,
    status: filters.gw_status ?? undefined,
  });

  const columns = useMemo(() => {
    const renderStatus = (row) => {
      return <OnlineStatus type={row.statusTranslated?.toLowerCase()} />;
    };

    const renderInterface = (row) => {
      const color =
        row.statusTranslated === "Online"
          ? "#2DCE89"
          : row.statusTranslated === "Offline"
          ? "#F5365C"
          : "#8898AA";
      return (
        <span>
          <Badge className="badge-dot mr-1">
            <i style={{ backgroundColor: color}}/>
            <span className="text-dark">{row.interface}</span>
          </Badge>
        </span>
      );
    };

    const renderPriority = (row) => {
      return (
        <div>
          <div>{row.priority}</div>
          {row.priorityType ? (
            <div className="text-muted">{row.priorityType}</div>
          ) : null}
        </div>
      );
    };

    const renderAction = (data) => {
      const openConfiguration = () =>
        history.push(
          `/admin/sdwan/device/${sn}/gateways/configurations/configuration/${data.id}`
        );

      return (
        <div className="mr-auto">
          <UncontrolledDropdown nav>
            <DropdownToggle nav>
              <i className="fas fa-ellipsis-v" style={{ fontSize: 14 }}></i>
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem onClick={openConfiguration}>
                Configuration
              </DropdownItem>
              <DropdownItem onClick={() => deleteGateway(data)}>
                Delete
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      );
    };

    return [
      {
        id: "statusTranslated",
        name: "Status",
        selector: (row) => row.statusTranslated,
        sortable: true,
        sortField: 'statusTranslated',
        cell: renderStatus,
        width: "100px",
        omit: visibleColumns?.includes("statusTranslated") ? false : true,
      },
      {
        id: "name",
        name: "Name",
        selector: (row) => row.name,
        sortable: true,
        sortField: 'name',
        omit: visibleColumns?.includes("name") ? false : true,
      },
      {
        id: "interface",
        name: "Interface",
        selector: (row) => row.interface,
        sortable: false,
        sortField: 'interface',
        cell: renderInterface,
        omit: visibleColumns?.includes("interface") ? false : true,
      },
      {
        id: "protocol",
        name: "Protocol",
        selector: (row) => row.protocol,
        sortable: true,
        sortField: 'protocol',
        omit: visibleColumns?.includes("protocol") ? false : true,
      },
      {
        id: "priority",
        name: "Priority",
        selector: (row) => row.priority,
        sortable: true,
        sortField: 'priority',
        cell: renderPriority,
        omit: visibleColumns?.includes("priority") ? false : true,
      },
      {
        id: "gateway",
        name: "Gateway",
        selector: (row) => row.gateway,
        sortable: true,
        sortField: 'gateway',
        omit: visibleColumns?.includes("gateway") ? false : true,
      },
      {
        id: "monitor",
        name: "Monitoring IP",
        selector: (row) => row.monitor,
        sortable: true,
        sortField: 'monitor',
        omit: visibleColumns?.includes("monitoring") ? false : true,
      },
      {
        id: "rtt",
        name: "RTT",
        selector: (row) => row.rtt,
        sortable: true,
        sortField: 'rtt',
        omit: visibleColumns?.includes("rtt") ? false : true,
      },
      {
        id: "rttD",
        name: "RTTd",
        selector: (row) => row.rttD,
        sortable: true,
        sortField: 'rttD',
        omit: visibleColumns?.includes("rttD") ? false : true,
      },
      {
        id: "loss",
        name: "Loss",
        selector: (row) => row.loss,
        sortable: true,
        sortField: 'loss',
        omit: visibleColumns?.includes("loss") ? false : true,
      },
      {
        id: "desc",
        name: "Description",
        selector: (row) => row.desc,
        sortable: true,
        sortField: 'desc',
        width: "250px",
        omit: visibleColumns?.includes("desc") ? false : true,
      },
      {
        width: "40px",
        cell: renderAction,
        allowOverflow: true,
        button: true,
      },
    ];
  }, [deleteGateway, sn, visibleColumns]);

  const onSubmitConfigurationColumns = (columns) => {
    setVisibleColumns(columns);
  };

  const createGateway = () => {
    history.push(`/admin/sdwan/device/${sn}/gateways/configurations/create`);
  };

  return (
    <Card className="mb-0">
      <CardHeader
        className="d-flex flex-1 align-items-center py-2 border-0 flex-wrap flex-md-nowrap"
        style={{ columnGap: 4 }}
      >
        <PrimeSearchInput
          onFilter={(text) => setSmartSearch(text)}
          className="flex-1"
          fullwidth
          size="sm"
          tooltip="Press 'Enter' to search."
          tooltipOptions={{ position: "top" }}
        />
        <DeviceGatewayFilter sn={sn} />
        <ConfigureInterfaceColumn
          onSubmit={onSubmitConfigurationColumns}
          columnOptions={columnOptions}
          visibleColumns={visibleColumns}
        />
        <RefreshButton onClick={dt.refetch} isLoading={dt.isFetching} />
        <Button size="sm" color="primary" onClick={createGateway}>
          Add
        </Button>
      </CardHeader>
      <CardBody className="p-2">
        <DataTable
          data={dt.data}
          defaultSortFieldId="statusTranslated"
          defaultSortAsc={false}
          responsive={true}
          columns={columns}
          persistTableHead
          noHeader
          progressPending={dt.isLoading || dt.isFetching}
          pagination
          paginationServer
          paginationTotalRows={dt.totalRows}
          paginationPerPage={dt.pageSize}
          onChangePage={dt.onChangePage}
          onChangeRowsPerPage={dt.onChangeRowsPerPage}
          sortServer
          onSort={dt.onSort}
          customStyles={{
            ...customStyles,
            tableWrapper: {
              style: {
                minHeight: "400px",
              },
            },
          }}
        />
      </CardBody>
    </Card>
  );
};

export default GatewayConfigurationTabPanel;
