import { useCallback } from "react";
// import { toast } from "react-toastify";
// import Swal from "sweetalert2";
import { useCreateDeviceGatewayGroupMutation } from "../services";

export function useCreateGatewayGroup(sn) {
  const [mutate, result] = useCreateDeviceGatewayGroupMutation();

  const bodyMap = (data) => ({
    description: data.description,
    name: data.name,
    pool_options: data.pool_options,
    priorities: [
      {
        name: data.tier_1,
        priority: 1
      },
      {
        name: data.tier_2,
        priority: 2
      },
      {
        name: data.tier_3,
        priority: 3
      },
      {
        name: data.tier_4,
        priority: 4
      },
      {
        name: data.tier_5,
        priority: 5
      },
    ].filter(priority => priority.name),
    trigger_level: data.trigger_level
  })

  const createGatewayGroup = useCallback(
    async (data) => {
      const body = bodyMap(data)
      console.log({body});
      
      if (sn) {
        await mutate({ sn, ...data }).unwrap();
      }
    },
    [mutate, sn]
  );

  return {
    createGatewayGroup,
    ...result,
  };
}
