import { useEffect, useState } from "react";

export function useTableColumnConfig(
  key = "table-config",
  defaultColumns = []
) {
  const [visibleColumns, setVisibleColumns] = useState(defaultColumns);

  useEffect(() => {
    const storedData = localStorage.getItem(key);
    if (storedData) {
      setVisibleColumns(JSON.parse(storedData));
    }
  }, [key]);

  useEffect(() => {
    const visCols =
      Array.isArray(visibleColumns) && visibleColumns.length > 0
        ? visibleColumns
        : [];
    const colString = JSON.stringify(visCols);
    localStorage.setItem(key, colString);
  }, [key, visibleColumns]);

  return [
    visibleColumns,
    setVisibleColumns,
  ];
}
