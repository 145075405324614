import EChartsReact from "echarts-for-react";
import { useGetavailabilityDetailMutation } from "features/device/service/usageApi";
import moment from "moment";
import React, { useEffect } from "react";

const ReportHeatmapEchart = (
  /* { data = [], timeGroup } */ { params, selectedSn, timeGroup }
) => {
  const [
    getHourlyDeviceAvailability,
    { data, isLoading: hourlySnDataFetching },
  ] = useGetavailabilityDetailMutation();

  useEffect(() => {
    getHourlyDeviceAvailability({
      params: {
        ...params,
        timeGroupping: timeGroup,
        status: undefined,
      },
      body: selectedSn,
    });
  }, [getHourlyDeviceAvailability, params, selectedSn, timeGroup]);

  // Preprocess data to create a series of timestamps and corresponding statuses
  const barData = (data || []).map((item) => ({
    // timestamp: moment(item.strTime).valueOf(), // Convert to timestamp (milliseconds)
    timestamp: moment(item.strTime).format("YYYY-MM-DD HH:mm"), // Format timestamp
    status: item.status,
  }));

  // ECharts options
  const options = {
    tooltip: {
      trigger: "axis",
      formatter: (params) => {
        const { value, color } = params[0];
        return `Time: ${moment(value[0]).format(
          "HH:mm"
        )}<br>Status: ${color === "#F5365C" ? 'Down' : 'Up'}`;
      },
    },
    grid: {
      height: "40%", // Set height relative to the chart container
      top: "5%",
      left: "2%",
      right: "2%",
      bottom: "5%", // Add some space for the visual map
    },
    xAxis: {
      type: "category", // Set x-axis type to time
      data: barData.map((item) => item.timestamp),
      axisLabel: {
        hideOverlap: true,
        align: "center",
        formatter: (value) => moment(value).format("HH:mm"),
      },
      splitLine: {
        show: false,
      },
      name: "🟥 Down",
      nameGap: 30,
      nameLocation: "center",
      axisTick: {
        alignWithLabel: true,
      },
      //   minInterval: 3600 * 1000, // 1 hour minimum interval for ticks
      //   maxInterval: 3600 * 1000, // 1 hour minimum interval for ticks
      //   tickInterval: 3600 * 1000, // 1 hour for ticks
    },
    yAxis: {
      show: false, // Hide the y-axis
    },
    series: [
      {
        name: "Status",
        type: "bar",
        barWidth: "100%",
        data: barData.map((item) => ({
          value: [item.timestamp, 1], // Use the timestamp for the x-value
          itemStyle: {
            color: item.status === "UP" ? "rgba(45, 206, 137, 0.1)" : "#F5365C", // Green for UP, Red for DOWN
          },
        })),
        emphasis: {
          focus: "series",
        },
      },
    ],
  };

  return (
    <EChartsReact
      option={options}
      style={{ height: "80px", width: "100%" }} // Set height to 80px
      showLoading={hourlySnDataFetching}
    />
  );
};

export default ReportHeatmapEchart;
