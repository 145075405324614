import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Collapse, FormText } from "reactstrap";

const CollapsibleHelper = ({ className, text, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <div className={classNames(className)}>
      <div className="d-flex align-items-center justify-content-between">
        <FormText color='muted'>{text}</FormText>
        <i className={classNames("pi cursor-pointer text-default", {
          "pi-chevron-down": !isOpen,
          "pi-chevron-up": isOpen,
        })} style={{ fontSize: 14 }} onClick={toggle}></i>
      </div>
      <Collapse isOpen={isOpen}>{children}</Collapse>
    </div>
  );
};

CollapsibleHelper.propTypes = {
  children: PropTypes.node.isRequired,
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
}

export default CollapsibleHelper;
