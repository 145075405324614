export const mapTriggerLevelDisplay = {
  down: "Member Down",
  downloss: "Packet Loss",
  downlatency: "High Latency",
  downlosslatency: "Packet Loss or High Latency",
};

export const triggerLevelOptions = [
  { value: "down", label: "Member Down" },
  { value: "downloss", label: "Packet Loss" },
  { value: "downlatency", label: "High Latency" },
  { value: "downlosslatency", label: "Packet Loss or High Latency" },
];
