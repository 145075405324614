import { useFormik } from "formik";
import React, { useState } from "react";
import CreatableSelect from "react-select/creatable";
import { toast } from "react-toastify";
import { Button, Col, Form, Modal, Row } from "reactstrap";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { FormikCheckbox, Loading } from "./../../../components";
import { useSetQuotaLimitSimCardMutation } from './../service/deviceApi';

function ModalSetQuota({ isOpen, toggle, title, selectedRows, uomOptions, quotaOptions, clearSelectedRows }) {
  const [loading, setLoading] = useState(false);

  const [setSimCardQuotaLimit] = useSetQuotaLimitSimCardMutation();

  const {
    handleSubmit,
    values,
    getFieldProps,
    setFieldValue,
    isSubmitting,
    errors,
    dirty,
    resetForm
  } = useFormik({
    initialValues: {
      sim1: true,
      sim2: false,
      quota: quotaOptions ? quotaOptions[0] : {},
      unit: uomOptions ? uomOptions[0] : {},
    },
    validationSchema: Yup.object().shape({
      quota: Yup.object().required("Select quota package."),
      unit: Yup.object().required("Select quota unit."),
    }),
    onSubmit: async ({ sim1, sim2, quota, unit }, { resetForm }) => {
      Swal.fire({
        icon: "question",
        title: "Are you sure want to update?",
        text: "Device quota.",
        showLoaderOnConfirm: true,
        showCancelButton: true,
        confirmButtonText: "Yes",
        reverseButtons: true,
        preConfirm: async () => {
          setLoading(true);
          try {
            for (let i=0; i < selectedRows.length; i++) {
              if (sim1) {
                await setSimCardQuotaLimit({
                  id: selectedRows[i].id,
                  slotNumber: 1,
                  limit: quota.value,
                  uom: unit.value,
                }).unwrap();
              }
              if (sim2) {
                await setSimCardQuotaLimit({
                  id: selectedRows[i].id,
                  slotNumber: 2,
                  limit: quota.value,
                  uom: unit.value,
                }).unwrap();
              }
            }
            toast.success("Set quota of devices success.");
            resetForm();
            clearSelectedRows();
            toggle();
          } catch (error) {
            toast.error(`Failed to set quota: ${error?.data?.message || "Unknown error"}`);
          } finally {
            setLoading(false);
          }
        },
        allowOutsideClick: () => !Swal.isLoading(),
      });
    },
  });

  return (
    <Modal
      className="modal-dialog-centered"
      size="sm"
      isOpen={isOpen}
      toggle={toggle}
    >
      <Form onSubmit={handleSubmit}>
        <div className="modal-header">
          <h3 className="modal-title" id="modalSetQuota">
            {title}
          </h3>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={toggle}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body py-2">
          <Row>
            <Col>
              <FormikCheckbox
                {...getFieldProps("sim1")}
                id="sim1"
                label="SIM 1"
                checked={values.sim1}
              />
            </Col>
            <Col>
              <FormikCheckbox
                {...getFieldProps("sim2")}
                id="sim2"
                label="SIM 2"
                checked={values.sim2}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <CreatableSelect
                options={quotaOptions}
                value={values.quota}
                onChange={(newValue) => setFieldValue("quota", newValue)}
                placeholder="Quota"
              />
              {errors.quota && (
                <span className="text-danger">{errors.quota}</span>
              )}
            </Col>
            <Col>
              <CreatableSelect
                options={uomOptions}
                value={values.unit}
                onChange={(newValue) => setFieldValue("unit", newValue)}
                placeholder="Uom"
              />
              {errors.unit && (
                <span className="text-danger">{errors.unit}</span>
              )}
            </Col>
          </Row>
          <Row className="mt-4">
            <Col className="text-center">
              <Button block color="default" type="submit" size="sm" disabled={loading || !dirty}>
                Apply
                {(isSubmitting || loading) && (
                  <Loading className="ml-2" />
                )}
              </Button>
            </Col>
          </Row>
          <Row className="my-2">
            <Col className="text-center">
              <Button
                block
                color="secondary"
                type="button"
                size="sm"
                onClick={() => {
                  resetForm();
                  toggle();
                }}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </div>
      </Form>
    </Modal>
  );
}

export default ModalSetQuota;
