import React, { useMemo } from "react";
import { MegaMenu } from "primereact/megamenu";
import PropTypes from "prop-types";
import { Card, CardBody, CardHeader } from "reactstrap";
import "./PageToolbar.css";
import { history } from "utils";
import { useParams } from "react-router";
import { useGetSdwanDevice } from "../hooks/useGetSdwanDevice";

const PageToolbar = ({ title, className }) => {
  const { sn } = useParams();

  const { data } = useGetSdwanDevice(sn);

  const menuItems = useMemo(() => {
    const interfaceItems = [];
    if (data && data.interfaces && data.interfaces.length > 0) {
      const menus = data.interfaces.map(({ id, description, networkDeviceName }) => {
        return {
          label: description,
          command: () => {
            if (networkDeviceName && networkDeviceName.toLowerCase().includes("ppp")) {
              // history.push(
              //   `/admin/sdwan/device/${sn}/interfaces/${id}/4g-configuration`
              // )
            } else {
              history.push(
                `/admin/sdwan/device/${sn}/interfaces/${id}/configuration`
              );
            }
          },
        };
      });

      interfaceItems.push(...menus);
    }

    return [
      {
        label: "Interface",
        items: [
          [
            {
              label: "General",
              items: [
                {
                  label: "Overview",
                  command: () =>
                    history.push(`/admin/sdwan/device/${sn}/interfaces`),
                },
              ],
            },
          ],
          [
            {
              label: "Configuration",
              items: interfaceItems,
            },
          ],
        ],
      },
      {
        label: "Gateways",
        items: [
          [
            {
              label: "",
              className: "d-none",
              items: [
                {
                  label: "Configuration",
                  command: () => history.replace(`/admin/sdwan/device/${sn}/gateways/configurations`)
                },
                {
                  label: "Group",
                  command: () => history.replace(`/admin/sdwan/device/${sn}/gateways/groups`)
                },
              ],
            },
          ],
        ],
      },
    ];
  }, [data, sn]);

  return (
    <Card className={className}>
      <CardHeader className="py-2">
        <div className="d-flex" style={{ columnGap: 8 }}>
          <button
            className="d-flex align-items-center p-button p-button-text p-0"
            onClick={history.goBack}
          >
            <i style={{ fontSize: 14 }} className="pi pi-chevron-left" />
          </button>
          <div className="d-flex align-items-center">
            <h3 style={{ marginBottom: 0, fontSize: 18, fontWeight: 600 }}>{title}</h3>
          </div>
        </div>
      </CardHeader>
      <CardBody className="py-2">
        <MegaMenu className="sdwan-toolbar" model={menuItems} />
      </CardBody>
    </Card>
  );
};

PageToolbar.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default PageToolbar;
