import { useCallback } from "react";
import { useCreateDeviceGatewayMutation } from "../services";

export function useCreateGateway(sn) {
  const [mutate, result] = useCreateDeviceGatewayMutation();

  const createGateway = useCallback(
    async (data) => {
      if (sn) {
        await mutate({ sn, ...data }).unwrap();
      }
    },
    [mutate, sn]
  );

  return {
    createGateway,
    ...result,
  };
}
