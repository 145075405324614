import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

const RefreshButton = ({ onClick, isLoading }) => {
  return (
    <button
      className="font-weight-normal btn btn-link text-default p-2"
      onClick={onClick}
    >
      <i className={classNames("fa fa-sync", { "fa-spin": isLoading })}></i>
    </button>
  );
};

RefreshButton.propTypes = {
  onClick: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default RefreshButton;
