import React from "react";
import PropTypes from "prop-types";
import { DefaultHeader } from "./../molecules";
import { Container } from "reactstrap";

const containerStyle = {
  marginTop: "-12rem",
};

function PageContent({ title, children, style, className }) {
  return (
    <>
      <DefaultHeader title={title} />
      <Container
        fluid
        style={{ ...containerStyle, ...style }}
        className={className}
      >
        {children}
      </Container>
    </>
  );
}

PageContent.propTypes = {
  title: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  style: PropTypes.object,
  className: PropTypes.string,
};

export default PageContent;
