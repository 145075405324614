import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { Col, FormGroup, Label } from "reactstrap";

const FormItem = ({ label, children, className, childClass, required }) => {
  return (
    <FormGroup row className={classNames("mb-2", className)}>
      <Label sm={12} md={3} className='font-weight-medium text-sm text-muted'>
        {label}
        {required ? <span className="text-danger">*</span> : null}
      </Label>
      <Col
        sm={12}
        md={9}
        className={classNames("d-flex align-items-center", childClass)}
      >
        {children}
      </Col>
    </FormGroup>
  );
};

FormItem.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  childClass: PropTypes.string,
  required: PropTypes.bool,
};

FormItem.defaultProps = {
  required: false,
}

export default FormItem;
