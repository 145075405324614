import { ButtonLinkIcon, PrimeDropdown, RowItem } from "components";
import { useDeviceGatewayFilter } from "features/sdwan/hooks/useDeviceGatewayFilter";
import { useDeviceGatewayOptions } from "features/sdwan/hooks/useDeviceGatewayOptions";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import React, { useState } from "react";
import {
  Button,
  Col,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Form,
  Nav,
  Row,
} from "reactstrap";

const statusOptions = [
  { value: 'Online', label: 'Online' },
  { value: 'Offline', label: 'Offline' },
];

const DeviceGatewayFilter = ({ sn }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { options: nameOptions } = useDeviceGatewayOptions(sn);
  const { filters, setFilters } = useDeviceGatewayFilter();

  const {
    values,
    dirty,
    isSubmitting,
    handleReset,
    setFieldValue,
    handleSubmit,
    resetForm,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: filters.gw_name,
      status: filters.gw_status,
    },
    onSubmit: (formData) => {
      const { name, status } = formData;
      setFilters({
        gw_name: name && name !== null && name !== "" ? name : undefined,
        gw_status: status && status !== null && status !== "" ? status : undefined,
      });
    },
  });

  const toggleMenu = () => {
    setIsOpen((prev) => !prev);
  };

  const onCancel = () => {
    setFilters({ gw_name: undefined, gw_status: undefined });
    resetForm();
  }

  return (
    <Nav navbar>
      <Dropdown nav isOpen={isOpen} toggle={toggleMenu}>
        <DropdownToggle nav>
          <ButtonLinkIcon
            label="Filters"
            icon="fa-filter"
            style={{ width: 100 }}
          />
        </DropdownToggle>
        <DropdownMenu right>
          <div style={{ minWidth: 400 }} className="p-3">
            <Form onSubmit={handleSubmit} onReset={handleReset}>
              <RowItem
                label="Gateway Name"
                value={
                  <PrimeDropdown
                    options={nameOptions}
                    value={values.name}
                    onChange={(e) => setFieldValue("name", e.value)}
                    placeholder="Select Gateway"
                    showClear
                  />
                }
                rightCol="col-7"
              />
              <RowItem
                label="Status"
                value={
                  <PrimeDropdown
                    options={statusOptions}
                    value={values.status}
                    onChange={(e) => setFieldValue("status", e.value)}
                    placeholder="Select Status"
                    showClear
                  />
                }
                rightCol="col-7"
              />
              <Row className="mt-4">
                <Col className="text-center">
                  <Button
                    className="m-0"
                    block
                    color="default"
                    size="sm"
                    type="submit"
                    disabled={!dirty || isSubmitting}
                  >
                    Apply
                  </Button>
                  <Button
                    className="m-0 mt-2"
                    block
                    color="secondary"
                    size="sm"
                    onClick={onCancel}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </DropdownMenu>
      </Dropdown>
    </Nav>
  );
};

DeviceGatewayFilter.propTypes = {
  sn: PropTypes.string.isRequired,
};
export default DeviceGatewayFilter;
