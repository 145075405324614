import { useMemo } from "react";
import { useGetAreasQuery } from "../service/areaApi";
import { useAuth } from "../../auth/hooks";

const allAreaOption = { value: -1, label: 'All Area', region: '' };

export function useArea(params = {}, withAllOption = true, queryOptions = {}) {
  const { user } = useAuth();

  const { data: paginatedResult, isLoading } = useGetAreasQuery({
    username: user ? user.username : undefined,
    ...params
  }, queryOptions);

  const options = useMemo(() => {
    const opts = [];

    if (user && user.allArea && withAllOption) {
      opts.push(allAreaOption);
    }

    if (paginatedResult && paginatedResult.content) {
      paginatedResult.content.forEach((d) => {
        opts.push({ value: d.id, label: d.name, region: d?.region?.name, regionId: d?.region?.id });
      });
    }

    return opts;
  }, [paginatedResult, user, withAllOption]);

  const data = useMemo(() => {
    if (!paginatedResult?.content) {
      return [];
    }

    return paginatedResult.content;
  }, [paginatedResult]);

  return {
    data,
    isLoading,
    options,
  };
}