import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { Alert, Button, Col, DropdownMenu, DropdownToggle, Nav, Row, Spinner, UncontrolledDropdown } from 'reactstrap';
import { ButtonLinkIcon, PrimeDropdown, PrimeMultiSelect, RowItem } from '../../../components';

const simCardStatusOptions = [
  { value: null, label: 'All' },
  { value: 'Activated', label: 'Activated' },
  { value: 'Deactivated', label: 'Deactivated' },
  { value: 'Missing CID', label: 'Missing SIA' },
];

const connectionStatusOptions = [
  { value: null, label: 'All' },
  { value: 'Connected', label: 'Connected' },
  { value: 'Disconnected', label: 'Disconnected' },
];

const signalStrengthOptions = [
  { value: null, label: 'All' },
  { value: 'No Signal', label: 'No Signal' },
  { value: 'Poor', label: 'Poor' },
  { value: 'Fair', label: 'Fair' },
  { value: 'Good', label: 'Good' },
  { value: 'Excellent', label: 'Excellent' },
];

const reachQuotaLimitOptions = [
  { value: null, label: 'All' },
  { value: 'true', label: 'Yes' },
  { value: 'false', label: 'No' },
];

const FilterDropdownDevice = (props) => {
  const {
    filteredGroupIds,
    filteredSimCardStatus,
    filteredCustomerIds,
    filteredRegionIds,
    filteredAreaIds,
    filteredConnectionStatus,
    filteredSignalStrength,
    filteredReachQuotaLimit,
    toggleOnFilter,
    groupOptions,
    customerOptions,
    regionOptions,
    areaOptions,
    regionId
  } = props;
  const [menuOpen, setMenuOpen] = useState(false);
  const toggle = () => setMenuOpen(!menuOpen);

  const { values, dirty, setFieldValue, handleSubmit } = useFormik({
    enableReinitialize: true,
    initialValues: {
      groupIds: filteredGroupIds ? filteredGroupIds.map(v => parseInt(v)) : [],
      customerIds: filteredCustomerIds ? filteredCustomerIds.map(v => parseInt(v)) : [],
      regionIds: filteredRegionIds ? filteredRegionIds.map(v => parseInt(v)) : [],
      areaIds: filteredAreaIds ? filteredAreaIds.map(v => parseInt(v)) : [],
      simCardStatus: filteredSimCardStatus,
      connectionStatus: filteredConnectionStatus,
      signalStrength: filteredSignalStrength,
      reachQuotaLimit: filteredReachQuotaLimit,
    },
    onSubmit: (values) => {
      const regionMap = areaOptions
      .filter(option => {
        const { regionIds = [] } = values; // Default to an empty array if undefined
        return regionIds.length === 0 || regionIds.includes(option.regionId);
      })
      .map(region => region.value);
      
      if (typeof props.onFilter === 'function') {
        props.onFilter({
          groupIds: values.groupIds,
          customerIds: values.customerIds,
          regionIds: values.regionIds,
          areaIds: values.areaIds.filter(id => regionMap.includes(id)),
          simCardStatus: values.simCardStatus,
          connectionStatus: values.connectionStatus,
          signalStrength: values.signalStrength,
          reachQuotaLimit: values.reachQuotaLimit,
        });
      }
      if (toggleOnFilter) {
        toggle();
      }
    }
  })

  useEffect(() => {
    const selectedGroupIds = filteredGroupIds && filteredGroupIds.length > 0 ? filteredGroupIds.map(v => parseInt(v)) : [];
    const selectedCustomerIds = filteredCustomerIds && filteredCustomerIds.length > 0 ? filteredCustomerIds.map(v => parseInt(v)) : [];
    const selectedRegionIds = filteredRegionIds && filteredRegionIds.length > 0 ? filteredRegionIds.map(v => parseInt(v)) : [];
    const selectedAreaIds = filteredAreaIds && filteredAreaIds.length > 0 ? filteredAreaIds.map(v => parseInt(v)) : [];

    setFieldValue('groupIds', selectedGroupIds);
    setFieldValue('customerIds', selectedCustomerIds);
    setFieldValue('regionIds', selectedRegionIds);
    setFieldValue('areaIds', selectedAreaIds);
    setFieldValue('simCardStatus', filteredSimCardStatus);
    setFieldValue('connectionStatus', filteredConnectionStatus);
    setFieldValue('signalStrength', filteredSignalStrength);
    setFieldValue('reachQuotaLimit', filteredReachQuotaLimit);
    // eslint-disable-next-line
  }, [
    filteredGroupIds,
    filteredSimCardStatus,
    filteredCustomerIds,
    filteredRegionIds,
    filteredAreaIds,
    filteredConnectionStatus,
    filteredSignalStrength,
    filteredReachQuotaLimit,
  ]);

  const onGroupChange = (e) => {
    setFieldValue('groupIds', e.value);
  }

  const onCustomerChange = (e) => {
    setFieldValue('customerIds', e.value);
  }

  const onRegionChange = (e) => {
    setFieldValue('regionIds', e.value);
  }

  const onAreaChange = (e) => {
    setFieldValue('areaIds', e.value);
  }

  const onSimStatusChange = (e) => {
    setFieldValue('simCardStatus',e.value);
  }

  const onConnectionStatusChange = (e) => {
    setFieldValue('connectionStatus', e.value);
  }

  const onSignalStrengthChange = (e) => {
    setFieldValue('signalStrength', e.value);
  }

  const onReachQuotaLimitChange = (e) => {
    setFieldValue('reachQuotaLimit', e.value);
  }

  const areaTemplate = (option) => ( 
    <div style={{ display: 'flex', flexDirection: 'column'}}>
        <div style={{ fontWeight: 'bold', fontSize: '12px'}}>
            {option.label}
        </div>
        <div style={{ fontSize: '10px', color: '#6c757d' }}>
            {option.region}
        </div>
    </div>
  );

  const modifiedAreaOptions = areaOptions.map(option => {
    const enabledRegionIds = [...values.regionIds];
    return {
        ...option,
        disabled: enabledRegionIds.length > 0 && !enabledRegionIds.includes(option.regionId)
    };
  });

  const showAlert = () => {
    const areaOpts = [...modifiedAreaOptions].filter(obj => obj.disabled === true).map(obj => obj.value)
    const selectedArea = values.areaIds
    return areaOpts.some(value => selectedArea.includes(value))
  }

  return (
    <Nav navbar>
      <UncontrolledDropdown nav direction="down" isOpen={menuOpen} toggle={toggle}>
        <DropdownToggle nav>
          <ButtonLinkIcon label="Filters" icon="fa-filter" />
        </DropdownToggle>
        <DropdownMenu right>
          <div style={{minWidth: 350}} className="p-3">
            <RowItem
              label="SIM Status"
              value={(
                <PrimeDropdown
                  options={simCardStatusOptions}
                  value={values.simCardStatus}
                  onChange={onSimStatusChange}
                />
              )}
              rightCol="col-7"
            />
            <RowItem
              label="Conn. Status"
              value={(
                <PrimeDropdown
                  options={connectionStatusOptions}
                  value={values.connectionStatus}
                  onChange={onConnectionStatusChange}
                />
              )}
              rightCol="col-7"
            />
            <RowItem
              label="Signal Strength"
              value={(
                <PrimeDropdown
                  options={signalStrengthOptions}
                  value={values.signalStrength}
                  onChange={onSignalStrengthChange}
                />
              )}
              rightCol="col-7"
            />
            <RowItem
              label="Reach Quota Limit"
              value={(
                <PrimeDropdown
                  options={reachQuotaLimitOptions}
                  value={values.reachQuotaLimit}
                  onChange={onReachQuotaLimitChange}
                />
              )}
              rightCol="col-7"
            />
            <RowItem
              label="Group"
              value={(
                <PrimeMultiSelect
                  filter
                  options={groupOptions}
                  value={values.groupIds}
                  onChange={onGroupChange}
                  placeholder="All Group"
                />
              )}
              rightCol="col-7"
            />
            <RowItem
              label="Customer"
              value={(
                <PrimeMultiSelect
                  filter
                  options={customerOptions}
                  value={values.customerIds}
                  onChange={onCustomerChange}
                  placeholder="All Customer"
                />
              )}
              rightCol="col-7"
            />
            <RowItem
              label="Region"
              value={(
                <PrimeMultiSelect
                  filter
                  options={regionOptions}
                  value={values.regionIds}
                  onChange={onRegionChange}
                  placeholder={regionId ? '"All Devices" only' : 'All Region'}
                  style={{maxWidth: '180px'}}
                  disabled={regionId ? true : false}
                />
              )}
              rightCol="col-7"
            />
            <RowItem
              label="Area"
              value={(
                <PrimeMultiSelect
                  filter
                   options={modifiedAreaOptions}
                  itemTemplate={areaTemplate}
                  value={values.areaIds}
                  onChange={onAreaChange}
                  placeholder={regionId ? '"All Devices" only' : 'All Area'}
                  style={{maxWidth: '180px'}}
                  disabled={regionId ? true : false}
                />
              )}
              rightCol="col-7"
            />
            {showAlert() && 
              <Row>
                <Col>
                <Alert
                  color="warning"
                  className="d-flex align-items-center"
                  style={{
                    backgroundColor: "#FFF4E5",
                    border: "0px solid #FFF4E5",
                    borderLeftWidth: "6px",
                    borderLeftColor: "#FFA800",
                    marginTop: "5px",
                    marginBottom: 0,
                  }}
                >
                  <span className="text-warning ml-1">
                    <span className="font-weight-bold">Invalid Filters.</span>
                    <span className="ml-1">
                      Some area filters were ignored due to a conflict with the region filter.
                    </span>
                  </span>
                </Alert>
                </Col>
              </Row>
            }
            <Row className="mt-4">
              <Col className="text-center">
                <Button className="m-0" block color="default" size="sm" type="button" onClick={handleSubmit} disabled={props.loading || !dirty}>
                  Apply
                  {props.loading && <Spinner className="ml-2" color="light" size="sm"/>}
                </Button>
                <Button className="m-0 mt-2" block color="secondary" size="sm" onClick={toggle}>Cancel</Button>
              </Col>
            </Row>
          </div>
        </DropdownMenu>
      </UncontrolledDropdown>
    </Nav>
  )
};

FilterDropdownDevice.propTypes = {
  loading: PropTypes.bool,
  onFilter: PropTypes.func,
  filteredGroupIds: PropTypes.array,
  filteredCustomerIds: PropTypes.array,
  filteredRegionIds: PropTypes.array,
  filteredAreaIds: PropTypes.array,
  filteredSimCardStatus: PropTypes.string,
  filteredConnectionStatus: PropTypes.string,
  filteredSignalStrength: PropTypes.string,
  filteredReachQuotaLimit: PropTypes.string,
  toggleOnFilter: PropTypes.bool,
  groupOptions: PropTypes.array,
  customerOptions: PropTypes.array,
  regionOptions: PropTypes.array,
  areaOptions: PropTypes.array,
};

FilterDropdownDevice.defaultProps = {
  loading: false,
  onFilter: () => {},
  filteredGroupIds: null,
  filteredCustomerIds: null,
  filteredAreaIds: null,
  filteredSimCardStatus: null,
  filteredConnectionStatus: null,
  filteredSignalStrength: null,
  filteredReachQuotaLimit: null,
  toggleOnFilter: false,
  groupOptions: [],
  customerOptions: [],
  regionOptions: [],
  areaOptions: [],
}

export default FilterDropdownDevice;
