import { useCallback } from "react";
import { useUpdateDeviceGatewayMutation } from "../services";

export function useUpdateGateway(sn) {
  const [mutate, result] = useUpdateDeviceGatewayMutation();

  const updateGateway = useCallback(
    async (uuid, data) => {
      const body = data
      
      if (sn && uuid) {
        await mutate({ sn, uuid, body }).unwrap();
      }
    },
    [mutate, sn]
  );

  return {
    updateGateway,
    ...result,
  };
}
