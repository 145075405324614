import React, { useState, forwardRef, useImperativeHandle } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { InputText } from "primereact/inputtext";
import classNames from "classnames";

const PrimeSearchInput = forwardRef(
  (
    { onFilter, placeholder, size, block, fullwidth, className, ...props },
    ref
  ) => {
    const [searchText, setSearchText] = useState("");

    const handleOnKeyDown = (e) => {
      if ((e.charCode || e.keyCode) === 13) {
        if (onFilter && typeof onFilter === "function") {
          onFilter(searchText);
        }
      }
    };

    useImperativeHandle(ref, () => ({
      clearFilter() {
        setSearchText("");
      },
      changeValue(value) {
        setSearchText(value);
      },
    }));

    return (
      <span
        className={classNames("p-input-icon-left", className)}
        style={{ width: fullwidth ? "100%" : "auto" }}
      >
        <i className="pi pi-search" />
        <InputText
          value={searchText}
          placeholder={placeholder}
          onChange={(e) => setSearchText(e.target.value)}
          onKeyDown={handleOnKeyDown}
          className={classnames({
            "p-inputtext-sm": size === "sm",
            "p-inputtext-lg": size === "lg",
            "p-d-block": block,
          })}
          style={{ width: fullwidth ? "100%" : "auto" }}
          {...props}
        />
      </span>
    );
  }
);

PrimeSearchInput.propTypes = {
  className: PropTypes.string,
  onFilter: PropTypes.func,
  size: PropTypes.oneOf(["sm", "md", "lg"]),
  placeholder: PropTypes.string,
  block: PropTypes.bool,
  fullwidth: PropTypes.bool,
};

PrimeSearchInput.defaultProps = {
  size: "md",
  onFilter: () => {},
  placeholder: "Search",
  block: false,
  fullwidth: false,
};

export default PrimeSearchInput;
