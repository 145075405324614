import { usePagination } from "hooks";
import { useGetDeviceGatewaysQuery } from "../services";
import { useMemo } from "react";

export function useDeviceGatewaysDatatable({ sn, ...params }) {
  const {
    pageNumber,
    pageSize,
    sortFields,
    sortOrder,
    onChangePage,
    onChangeRowsPerPage,
    onSort,
  } = usePagination();
  const { data: paginatedResult, ...query } = useGetDeviceGatewaysQuery(
    {
      pageNumber: pageNumber > 0 ? pageNumber - 1 : 0,
      pageSize,
      sortFields: sortFields ? sortFields : undefined,
      sortOrder: sortOrder ? sortOrder : undefined,
      sn,
      ...params,
    },
    { skip: !sn }
  );

  const data = useMemo(() => {
    if (!paginatedResult?.content) return [];

    return paginatedResult.content;
  }, [paginatedResult]);

  const totalRows = useMemo(() => {
    if (!paginatedResult) return 0;

    return paginatedResult.totalElements;
  }, [paginatedResult]);

  return {
    data,
    pageSize,
    totalRows,
    ...query,
    onChangeRowsPerPage,
    onChangePage,
    onSort,
  };
}
