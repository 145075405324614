import { StringParam, useQueryParams } from "use-query-params";

export function useDeviceGatewayFilter() {
  const [filters, setFilters] = useQueryParams({
    gw_name: StringParam,
    gw_status: StringParam,
  });

  return {
    filters,
    setFilters,
  };
}
