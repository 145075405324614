import { useMemo } from "react";
import { useGetDeviceGatewaysQuery } from "../services";

export function useDeviceGatewayOptions(sn) {
  console.log("🚀 ~ useDeviceGatewayOptions ~ sn:", sn)
  const { data } = useGetDeviceGatewaysQuery({ sn }, { skip: !sn });

  const options = useMemo(() => {
    if (!data) return [];

    return data.content.map(({ name }) => ({ value: name, label: name }));
  }, [data]);

  return {
    options
  }
}
