import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import './../styles.css';

import {
  InterfaceList,
  InterfaceConfiguration4G,
  DeviceList,
  InterfaceConfiguration,
  GatewayList,
  CreateGatewayGroupConfiguration,
  EditGatewayGroupConfiguration,
  CreateGatewayConfiguration,
  EditGatewayConfiguration
} from "../pages";

function InterfaceRouter() {
  return (
    <Switch>
      <Route exact path={`/admin/sdwan/device`} component={DeviceList} />
      <Route
        exact
        path={`/admin/sdwan/device/:sn/interfaces`}
        component={InterfaceList}
      />
      <Route
        exact
        path={`/admin/sdwan/device/:sn/interfaces/:interfaceId/configuration`}
        component={InterfaceConfiguration}
      />
      <Route
        exact
        path={`/admin/sdwan/device/:sn/interfaces/:interfaceId/4g-configuration`}
        component={InterfaceConfiguration4G}
      />
      <Route
        exact
        path={`/admin/sdwan/device/:sn/gateways/:type`}
        component={GatewayList}
      />
      <Route
        exact
        path={`/admin/sdwan/device/:sn/gateways/configurations/create`}
        component={CreateGatewayConfiguration}
      />
      <Route
        exact
        path={`/admin/sdwan/device/:sn/gateways/configurations/configuration/:gatewayId`}
        component={EditGatewayConfiguration}
      />
      <Route
        exact
        path={`/admin/sdwan/device/:sn/gateways/groups/create`}
        component={CreateGatewayGroupConfiguration}
      />
      <Route
        exact
        path={`/admin/sdwan/device/:sn/gateways/groups/configuration/:gatewayGroupId`}
        component={EditGatewayGroupConfiguration}
      />
      <Redirect path="*" to="/admin/sdwan/device" />
    </Switch>
  );
}

export default InterfaceRouter;
