import React from 'react';
import { Row, Col } from 'reactstrap';
import {unknown} from './../../../../utils';
import { Gap, RowItem } from './../../../../components';
import moment from 'moment';

export function DeviceUsageSim({simCard}) {
  const {
    quotaLimitInHuman,
    slotNumber,
    usageInHuman,
    reachQuotaLimit,
    quotaRunsOutPredictionInDays,
    usageInPercentage,
    imsi,
    imei,
    overQuotaInHuman,
    overQuotaInPercentage,
    remainingQuotaInHuman,
    remainingQuotaInPercentage,
    gsmNumber,
    circuitNumber,
    providerRemainingQuotaInHuman,
    providerRemainingQuotaInPercentage,
    providerRemainingQuotaLastUpdated
  } = simCard;

  const formatQuotaDate = (timestamp) => {
    const momentDate = moment(timestamp);
    const today = moment();
    const isToday = momentDate.isSame(today, 'day');
  
    if (isToday) {
      return `Today, ${momentDate.format('HH:mm:ss')}`;
    } else {
      return momentDate.format('DD MMMM YYYY, HH:mm:ss');
    }
  }

  function renderNoData(imsi) {
    return imsi.trim().substring(0, 5) === "51001" ? 'Indosat SIM Card Cannot be Monitored' : 'No Data Received'
  }
  

  return (
    <Row className="mb-4">
      <Col>
        <h3>SIM {slotNumber.toString() || unknown}</h3>
        <Gap height={15} />
        <RowItem stripped label="SIA" value={circuitNumber || unknown} />
        <RowItem label="IMEI" value={imei || unknown} />
        <RowItem stripped label="IMSI" value={imsi || unknown} />
        <RowItem label="GSM Number" value={gsmNumber || unknown} />
        <RowItem stripped label="Quota" value={quotaLimitInHuman || unknown} />
        <RowItem label="Data Usage" value={`${usageInHuman || 0} (${usageInPercentage || 0} %)`} />
        <RowItem stripped label="Remaining Quota"/>
        <RowItem
          label="Measured by Modem"
          value={`${remainingQuotaInHuman} (${remainingQuotaInPercentage || 0} %)`}
          offset
          onlyLabelOffset
          stripped
        />
        <RowItem
          label="Provider Data"
          value={
            <>
              <div>{providerRemainingQuotaInHuman ? `${providerRemainingQuotaInHuman}  (${providerRemainingQuotaInPercentage} %)` : renderNoData(imsi)}</div>
              <div className='text-muted'>{providerRemainingQuotaLastUpdated ? `Last updated at ${formatQuotaDate(providerRemainingQuotaLastUpdated)}` : 'Last updated at -'}</div>
            </>
          }
          offset
          onlyLabelOffset
          stripped
        />
        <RowItem label="Reach Quota Limit" value={reachQuotaLimit ? 'Yes' : 'No'} />
        <RowItem stripped label="Over Quota Usage" value={`${overQuotaInHuman} (${overQuotaInPercentage || 0} %)`} />
        <RowItem label="Quota Runs Out Prediction" value={typeof quotaRunsOutPredictionInDays !== 'undefined' ? quotaRunsOutPredictionInDays + (quotaRunsOutPredictionInDays > 1 ? ' Days' : ' Day') : unknown } />
      </Col>
    </Row>
  )
};
