import React, {useMemo, useState} from 'react';
import moment from 'moment';
import _ from 'lodash';
import DataTable from 'react-data-table-component';
import { StatusBadge } from './../../../components';
import { uppercase, unknown, paginationRowsPerPageOptions } from './../../../utils';
import { SignalStrengthStatus } from 'features/device';
import { customStyles3 } from "./../../../utils/datatableStyles";
import DeviceAvailabilityDetailModal from './DeviceAvailabilityDetailModal';
import { Tooltip } from 'primereact/tooltip';

function ReportTable({ data, loading, visibleColumns, params, keyField = 'id', reportType }) {

  const tooltipStyle = {
    maxWidth: 'fit-content', 
    whiteSpace: 'nowrap'
  };

  const { startTime, endTime, label } = params;    
  const columns = useMemo(() => {

    const getTimeFormat = () => {
      return endTime.diff(startTime, "days") <= 1 && reportType !== 'sla' ? "DD/MM/YYYY HH:mm:ss" : "DD/MM/YYYY"
    };

    const renderUsage = (row) => {
      const {bytesUsage, quotaLimitInByte, quotaLimitInHuman} = row;
      const usage = ((bytesUsage / quotaLimitInByte) * 100).toFixed(2);
      const usageInHuman = `${usage}%`;
      const type = usage < 30 ? "success" : (usage < 60 ? "warning" : "danger");
      return <StatusBadge label={`${usageInHuman} of ${quotaLimitInHuman}`} type={type} isUppercase />
    }
    return [
      {
        name: uppercase('TIME'),
        sortable: true,
        selector: row => row?.strTime,
        center: false,
        cell: ({strTime}) => moment(strTime).isValid() ? moment(strTime).format(getTimeFormat()) : strTime,
        omit: visibleColumns?.includes('strTime') ? false : true,
      },
      {
        name: uppercase('DATETIME'),
        sortable: true,
        selector: row => row?.integrationDate,
        cell: ({integrationDate}) => moment(integrationDate).isValid() ? moment(integrationDate).format(getTimeFormat()) : integrationDate,
        omit: visibleColumns?.includes('integrationDate') ? false : true,
      },
      {
        name: uppercase('Customer Name'),
        sortable: true,
        selector: row => row?.customerName,
        left: true,
        hide: "md",
        omit: visibleColumns?.includes('customerName') ? false : true,
      },
      {
        name: uppercase('Customer Name'),
        sortable: true,
        selector: row => row?.customer?.name,
        left: true,
        hide: "md",
        cell: ({ customer }) => customer?.name || unknown,
        omit: visibleColumns?.includes('customer.name') ? false : true,
      },
      {
        name: uppercase('Serial Number'),
        selector: row => row?.serialNumber,
        sortable: true,
        wrap: true,
        hide: "md",
        cell: ({serialNumber}) => serialNumber === "null" ? unknown : reportType === 'sla' ? <strong style={{color: '#2065AC', cursor: 'pointer'}} onClick={() => handleClickSn(serialNumber)}>{serialNumber}</strong> : serialNumber,       
        // cell: ({serialNumber}) => serialNumber === "null" ? unknown : serialNumber,  
        omit: visibleColumns?.includes('serialNumber') ? false : true,
      },
      {
        name: uppercase('Serial Number'),
        sortable: true,
        selector: row => row?.sn,
        wrap: true,
        hide: "md",
        cell: ({sn}) => sn || unknown,
        omit: visibleColumns?.includes('sn') ? false : true,
      },
      {
        name: uppercase('Device Type'),
        sortable: true,
        hide: "md",
        cell: ({type}) => type || unknown,
        omit: visibleColumns?.includes('type') ? false : true,
      },
      {
        name: "SIM 1 IMSI",
        sortable: false,
        hide: "md",
        cell: ({ simCards }) => {
          if (simCards && simCards.length === 0) {
            return unknown;
          }
          const simCard = _.find(simCards, { slotNumber: 1 });

          if (!simCard) {
            return unknown;
          }

          return simCard.imsi
        },
        omit: visibleColumns?.includes("sim1Imsi") ? false : true,
      },
      {
        name: "SIM 2 IMSI",
        sortable: false,
        hide: "md",
        cell: ({ simCards }) => {
          if (simCards && simCards.length === 0) {
            return unknown;
          }
          const simCard = _.find(simCards, { slotNumber: 2 });

          if (!simCard) {
            return unknown;
          }

          return simCard.imsi
        },
        omit: visibleColumns?.includes("sim2Imsi") ? false : true,
      },
      {
        name: "SIM 1 IMEI",
        sortable: false,
        hide: "md",
        cell: ({ simCards }) => {
          if (simCards && simCards.length === 0) {
            return unknown;
          }
          const simCard = _.find(simCards, { slotNumber: 1 });

          if (!simCard) {
            return unknown;
          }

          return simCard.imei
        },
        omit: visibleColumns?.includes("sim1Imei") ? false : true,
      },
      {
        name: "SIM 2 IMEI",
        sortable: false,
        hide: "md",
        cell: ({ simCards }) => {
          if (simCards && simCards.length === 0) {
            return unknown;
          }
          const simCard = _.find(simCards, { slotNumber: 2 });

          if (!simCard) {
            return unknown;
          }

          return simCard.imei
        },
        omit: visibleColumns?.includes("sim2Imei") ? false : true,
      },
      {
        name: "SIM 1 Operator",
        sortable: false,
        hide: "md",
        cell: ({ simCards }) => {
          if (simCards && simCards.length === 0) {
            return unknown;
          }
          const simCard = _.find(simCards, { slotNumber: 1 });

          if (!simCard) {
            return unknown;
          }

          return simCard.operator
        },
        omit: visibleColumns?.includes("sim1Operator") ? false : true,
      },
      {
        name: "SIM 2 OPERATOR",
        sortable: false,
        hide: "md",
        cell: ({ simCards }) => {
          if (simCards && simCards.length === 0) {
            return unknown;
          }
          const simCard = _.find(simCards, { slotNumber: 2 });

          if (!simCard) {
            return unknown;
          }

          return simCard.operator
        },
        omit: visibleColumns?.includes("sim2Operator") ? false : true,
      },
      {
        name: uppercase('IMEI'),
        selector: row => row?.imei,
        sortable: true,
        center: false,
        hide: "md",
        omit: visibleColumns?.includes('imei') ? false : true,
      },
      {
        name: uppercase('IMSI'),
        selector: row => row?.imsi,
        sortable: true,
        center: false,
        hide: "md",
        omit: visibleColumns?.includes('imsi') ? false : true,
      },
      {
        name: uppercase('SIA'),
        selector: row => row?.sia,
        sortable: true,
        center: false,
        hide: "md",
        omit: visibleColumns?.includes('sia') ? false : true,
      },
      {
        name: uppercase('Quota Limit'),
        selector: row => row?.quotaLimitInHuman,
        sortable: true,
        center: false,
        hide: "md",
        omit: visibleColumns?.includes('quotaLimitInHuman') ? false : true,
      },
      {
        name: uppercase('Usage'),
        selector: row => row?.bytesUsage,
        sortable: true,
        center: false,
        wrap: true,
        cell: (row) => renderUsage(row),
        omit: visibleColumns?.includes('usageDescription') ? false : true,
      },
      {
        name: uppercase('Location'),
        sortable: true,
        selector: row => row?.location,
        center: false,
        hide: "md",
        cell: ({ location }) => location && location !== 'null' ? location : unknown,
        omit: visibleColumns?.includes('location') ? false : true,
      },
      {
        name: uppercase('Area'),
        selector: row => row?.area?.name,
        sortable: true,
        center: false,
        hide: "md",
        cell: ({ area }) => {
          if (typeof area === 'object' && area !== null) {
            return area.name || unknown;
          }
          return area && area !== 'null' ? area : unknown
        },
        omit: visibleColumns?.includes('area') ? false : true,
      },
      {
        name: uppercase('Signal'),
        selector: row => row?.signalCategory,
        sortable: true,
        hide: "md",
        cell: ({ signalCategory }) => <SignalStrengthStatus status={signalCategory} />,
        omit: visibleColumns?.includes('signalCategory') ? false : true,
      },
      {
        name: uppercase('Operator'),
        sortable: true,
        selector: row => row?.operator,
        center: false,
        hide: "md",
        cell: ({ operator }) => _.upperFirst(operator),
        omit: visibleColumns?.includes('operator') ? false : true,
      },
      {
        name: uppercase('Uptime'),
        sortable: true,
        selector: row => row?.totalUptime,
        center: false,
        hide: "md",
        // cell: ({ totalUptime, uptimeUnit }) => `${totalUptime}${uptimeUnit}`,
        cell: ({ totalUptimeFormatted, totalUptime, uptimeUnit }) => reportType === 'sla' ? `${totalUptimeFormatted}` : `${totalUptime}${uptimeUnit}`,
        omit: visibleColumns?.includes('totalUptime') ? false : true,
      },
      {
        name: uppercase('Downtime'),
        sortable: true,
        selector: row => row?.totalDowntime,
        center: false,
        hide: "md",
        cell: ({ totalDowntimeFormatted, totalDowntime, downtimeUnit }) => reportType === 'sla' ? `${totalDowntimeFormatted}` : `${totalDowntime}${downtimeUnit}`,
        omit: visibleColumns?.includes('totalDowntime') ? false : true,
      },
      {
        name: reportType === 'sla' ? <span style={{display: 'flex'}}>{uppercase('Availability')}<i className="mdi mdi-help-circle ml-2 custom-target-icon"></i><Tooltip target=".custom-target-icon" position="left" content='(Uptime / Elapsed Time)%' style={tooltipStyle} /></span> : uppercase('Availability'),
        sortable: true,
        selector: row => row?.sla,
        center: false,
        hide: "md",
        // cell: ({ sla }) => (typeof sla === 'number') ? `${sla.toFixed(2) * 100}%` : `${parseFloat(sla).toFixed(2) * 100}%`,
        cell: ({ sla }) => (typeof sla === 'number') ? `${(sla * 100).toFixed(2)}%` : `${parseFloat(sla * 100).toFixed(2)}%`,
        omit: visibleColumns?.includes('sla') ? false : true,
      },
      {
        name: uppercase('Status'),
        sortable: true,
        selector: row => row?.integrationStatus,
        hide: "md",
        cell: ({ integrationStatus }) => <StatusBadge type={_.toLower(integrationStatus) === 'onboard' ? 'success' : 'danger'} label={integrationStatus} />,
        omit: visibleColumns?.includes('integrationStatus') ? false : true,
      },
    ]
  }, [visibleColumns, reportType, tooltipStyle, endTime, startTime]);

  const [isSnModalOpen, setIsSnModalOpen] = useState(false)
  const toggleSnModal = () => {
    setIsSnModalOpen(!isSnModalOpen)
  }

  const handleClickSn = (sn) => {
    setSelectedSn(sn)
    setIsSnModalOpen(true)
  }

  const snList = data.map(obj => {
    return obj.serialNumber ? obj.serialNumber : null
  })

  const [selectedSn, setSelectedSn] = useState()
  
  return (
    <>
      <DataTable
        title=""
        keyField={keyField}
        data={data}
        columns={columns}
        defaultSortField="customerName"
        striped
        noHeader
        highlightOnHover
        persistTableHead
        progressPending={loading}
        pagination
        paginationPerPage={10}
        paginationRowsPerPageOptions={paginationRowsPerPageOptions}
        customStyles={customStyles3}
      />
      {isSnModalOpen && (
        <DeviceAvailabilityDetailModal isSnModalOpen={isSnModalOpen} toggleSnModal={toggleSnModal} snList={snList} sn={selectedSn} dateFilter={{startTime, endTime, label}}/>
      )}
    </>
  )
}

export default ReportTable
