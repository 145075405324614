import React from "react";
import { FormText } from "reactstrap";
import PropTypes from "prop-types";

const FormItemPlaceholder = ({ label, value, helperText }) => {
  return (
    <div>
      {label ? <label>{label}</label> : null}
      <div>{value}</div>
      {helperText ? <FormText color="muted">{helperText}</FormText> : null}
    </div>
  );
};

FormItemPlaceholder.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any.isRequired,
  helperText: PropTypes.string,
}

export default FormItemPlaceholder;
