import { useMemo } from "react";
import { useUseGetSdwanReferenceQuery } from "../services";

/**
 * “ipv4-conf-types” for IPv4 Configuration Type(s)
 * “ipv4-gateway-rules” for IPv4 Gateway Rules
 * “ipv6-conf-types” for IPv4 Configuration Type(s)
 * “ipv6-gateway-rules” for IPv4 Gateway Rules
 * “gw-group-trigger-level” for Gateway Group Trigger Level
 * “gw-group-pool-opts”  for Gateway Group Pool Options
 * "priority-conf" for Gateway Priority (List Gateway)
 * “list-interfaces” for List Interface in General Configuration of Create/Edit Gateway
 * “ip-family for IP Family optoins
 */
export function useSdwanReference(name) {
  const { data, ...result } = useUseGetSdwanReferenceQuery(name, {
    skip: !name,
  });

  const options = useMemo(() => {
    if (!data) return [];

    return data.map(({ label, value, ...rest }) => ({ value, label, ...rest }));
  }, [data]);

  return {
    data,
    options,
    ...result,
  };
}
