import { useCallback } from "react";
import { useUpdateNetworkInterfaceByIdMutation } from "../services";
import Swal from "sweetalert2";
import { toast } from "react-toastify";

export function useUpdateInterface(id) {
  const [mutate] = useUpdateNetworkInterfaceByIdMutation();

  const bodyMap = (data) => ({
    ...data,
    networkDeviceName: undefined,
    identifier: undefined,
  });

  return useCallback(
  (data) => {
    const body = bodyMap(data);
    Swal.fire({
      title: `Update ${data.descr}?`,
      text: `This configuration will be applied`,
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes, Update",
      cancelButtonText: "No, Go Back",
      showLoaderOnConfirm: true,
      confirmButtonColor: "#2065AC",
      reverseButtons: true,
      preConfirm: async () => {
        try {
          const response = await mutate({ id, body });
          if (response.error) {
            throw response.error;
          }
          toast.success("Interfaces updated.");
          return true;
        } catch (error) {
          Swal.showValidationMessage(`
            Request failed: ${error.data.message || error.data.message}
          `);
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
  },
  [id, mutate]
);
}
