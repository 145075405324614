import { PageContent } from "components";
import React from "react";
import PageToolbar from "../component/PageToolbar";
import GatewayGroupForm from "../component/Form/GatewayGroupForm";
import { useSdwanReference } from "./../hooks/useSdwanReference";
import { useCreateGatewayGroup } from "../hooks/useCreateGatewayGroup";
import { toast } from "react-toastify";
import { history } from "utils";
import { useParams } from "react-router";

const CreateGatewayGroupConfiguration = () => {
  const { sn } = useParams();
  const { options: gatewaListOptions } = useSdwanReference("priority-conf");
  const { options: poolOptions } = useSdwanReference("gw-group-pool-opts");
  const { options: triggerLevelOptions } = useSdwanReference("gw-group-trigger-level");
  const { createGatewayGroup} = useCreateGatewayGroup(sn);

  const onCreateGwGroup = async (values) => {
    await createGatewayGroup(values);
    toast.success("Command to create Gateway Group sent.");
    history.goBack();
  };


  return (
    <PageContent style={{ marginTop: "-14rem" }}>
      <PageToolbar title="Create Gateway Group" className="mb-3" />
      <GatewayGroupForm
        gatewaListOptions={gatewaListOptions}
        poolOptions={poolOptions}
        triggerLevelOptions={triggerLevelOptions}
        onSubmit={onCreateGwGroup}
      />
    </PageContent>
  );
};

export default CreateGatewayGroupConfiguration;
