import React from 'react';
import { useSelector } from 'react-redux';
import { useGetProfileQuery } from '../service/authApi';
import { FullscreenLoading } from './../../../components';
import { useGetUserImsisQuery } from './../../device/service/deviceApi';

const UserMiddleware = ({ children }) => {
  const { token, username } = useSelector(state => state.auth);

  const { isLoading: loadingUserProfile } = useGetProfileQuery(username, { skip: !token && !username });

  const { isLoading: loadingUserImsis } = useGetUserImsisQuery(username, { skip: !token && !username });

  if (loadingUserProfile || loadingUserImsis) {
    return (
      <FullscreenLoading />
    )
  }

  return children;
}

export default UserMiddleware
