import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Button,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";
import PropTypes from 'prop-types';
import { MultiSelect } from "primereact/multiselect";
import { ButtonLinkIcon } from "../../../components";

const ConfigureInterfaceColumn = ({ label, columnOptions, visibleColumns, onSubmit }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedColumns, setSelectedColumns] = React.useState(visibleColumns);
  const [changes, setChanges] = React.useState(false);

  const toggle = () => setIsOpen(!isOpen);

  const handleSelectedColumns = (e) => {
    setSelectedColumns(e.value);
    setChanges(true)
  };
  
  const handleSubmit = () => {
    onSubmit(selectedColumns);
    toggle();
  };

  useEffect(() => {
    setSelectedColumns(visibleColumns);
  }, [visibleColumns])

  return (
    <Nav navbar>
      <UncontrolledDropdown
        nav
        direction="down"
        isOpen={isOpen}
        toggle={toggle}
      >
        <DropdownToggle nav>
          <ButtonLinkIcon label={label} icon="fa-cogs" />
        </DropdownToggle>
        <DropdownMenu right>
          <div style={{ minWidth: 350 }} className="p-3">
            <Row className="text-sm mt-3">
              <div className="col">
                <MultiSelect
                  filter
                  options={columnOptions}
                  value={selectedColumns}
                  onChange={handleSelectedColumns}
                  style={{ fontSize: 12 }}
                  placeholder="Filter Column"
                />
              </div>
            </Row>
            <Row className="mt-4">
              <Col className="text-center">
                <Button
                  className="m-0"
                  block
                  color="default"
                  size="sm"
                  type="button"
                  onClick={handleSubmit}
                  disabled={!changes}
                >
                  Apply
                </Button>
                <Button
                  className="m-0 mt-2"
                  block
                  color="secondary"
                  size="sm"
                  onClick={toggle}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </div>
        </DropdownMenu>
      </UncontrolledDropdown>
    </Nav>
  );
};

ConfigureInterfaceColumn.propTypes = {
  onSubmit: PropTypes.func,
  columnOptions: PropTypes.arrayOf(PropTypes.object).isRequired
}

ConfigureInterfaceColumn.defaultProps = {
  onSubmit: () => {}
}

export default ConfigureInterfaceColumn;
