import { FullscreenLoading, PageContent } from "components";
import React, { useMemo } from "react";
import PageToolbar from "../component/PageToolbar";
import GatewayGroupForm from "../component/Form/GatewayGroupForm";
import { useParams } from "react-router";
import { useGetDeviceGatewayGroupByIdQuery } from "../services";

const EditGatewayGroupConfiguration = () => {
  const { gatewayGroupId } = useParams();
  const { data, isLoading } = useGetDeviceGatewayGroupByIdQuery(
    gatewayGroupId,
    { skip: !gatewayGroupId }
  );

  const title = useMemo(() => {
    if (!data) return "Gateway Group Configuration";

    return `${data.name}'s Configuration`;
  }, [data]);

  return (
    <PageContent style={{ marginTop: "-14rem" }}>
      <PageToolbar title={title} className="mb-3" />
      {isLoading ? <FullscreenLoading /> : <GatewayGroupForm data={data} />}
    </PageContent>
  );
};

export default EditGatewayGroupConfiguration;
