import { Formik } from "formik";
import React from "react";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import FormItem from "../FormItem";
import { InputSwitch } from "primereact/inputswitch";
import { TritronikInputText, TritronikSelect } from "components";
import FormItemPlaceholder from "../FormItemPlaceholder";
import { history } from "utils";

const InterfaceConfiguration4GForm = () => {
  return (
    <Formik
      initialValues={{
        enabled: false,
        name: "",
        mtu: "",
        prevent_removal: false,
        ipv4: {
          type: "static",
          address: "192.168.0.1",
          segment: "",
          gw_rule: "",
        },
        ipv6: {
          type: "static",
          address: "2001:0000:130F:0000:0000:09C0:876A:130B",
          segment: "",
          gw_rule: "",
        },
      }}
    >
      {({ values, touched, errors, setFieldValue }) => {
        return (
          <div className="position-relative">
            <Card className="mb-3">
              <CardBody>
                <Row>
                  <Col md="3">
                    <h3 className="mt-md-2 font-weight-medium">General Configuration</h3>
                  </Col>
                  <Col md="9">
                    <FormItem label="Enable">
                      <InputSwitch
                        checked={values.enabled}
                        onChange={(e) => setFieldValue("enabled", e.value)}
                      />
                    </FormItem>
                    <FormItem label="Interface Name">
                      <TritronikInputText name="name" small />
                    </FormItem>
                    <FormItem label="Prevent Interface Removal">
                      <InputSwitch
                        checked={values.prevent_removal}
                        onChange={(e) =>
                          setFieldValue("prevent_removal", e.value)
                        }
                      />
                    </FormItem>
                    <FormItem label="Identifier">
                      <FormItemPlaceholder
                        value="lan"
                        helperText="The internal configuration identifier of this interface."
                      />
                    </FormItem>
                    <FormItem label="Device">
                      <FormItemPlaceholder
                        value="igc2"
                        helperText="The assigned network device name of this interface."
                      />
                    </FormItem>
                    <FormItem label="MTU">
                      <TritronikInputText
                        name="mtu"
                        small
                        helperText="If you leave this field blank, the adapter's default MTU will be used. This is typically 1500 bytes but can vary in some circumstances."
                      />
                    </FormItem>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card style={{ marginBottom: "7rem" }}>
              <CardBody>
                <Row>
                  <Col md="3" sm="12">
                    <h3 className="mt-md-2 font-weight-medium">IPv4 Configuration</h3>
                  </Col>
                  <Col md="9" sm="12">
                    <FormItem label="IPv4 Configuration Type">
                      <TritronikSelect
                        value={values.ipv4.type}
                        name="ipv4.type"
                        options={[]}
                        onChange={(e) => setFieldValue("ipv4.type", e.value)}
                        invalid={touched.ipv4?.type && errors.ipv4?.type}
                        error={errors.ipv4?.type}
                      />
                    </FormItem>
                    <FormItem label="Service Provider">
                      <div className="d-flex flex-1 align-items-center w-100" style={{ columnGap: 8 }}>
                        <TritronikSelect placeholder='Select provider' />
                        <TritronikSelect placeholder='Select plan' />
                      </div>
                    </FormItem>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card
              className="mb-0 position-fixed rounded-0"
              style={{ padding: "0px 30px", bottom: 0, left: 0, right: 0 }}
            >
              <CardBody
                className="d-flex justify-content-end"
                style={{ gap: 8 }}
              >
                <Button color="secondary" size="sm" className="px-5" onClick={history.goBack}>
                  Cancel
                </Button>
                <Button color="primary" size="sm" className="px-5">
                  Save
                </Button>
              </CardBody>
            </Card>
          </div>
        );
      }}
    </Formik>
  );
};

export default InterfaceConfiguration4GForm;
