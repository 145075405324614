import React, { useMemo } from "react";
import { TritronikInputText, TritronikSelect } from "components";
import { Formik } from "formik";
import { Button, Card, CardBody, Col, FormText, Row } from "reactstrap";
import FormItem from "./../FormItem";
import CollapsibleHelper from "./../CollapsibleHelper";
import { history } from "utils";
import PropTypes from "prop-types";

const GatewayGroupForm = ({ data, gatewaListOptions, poolOptions, triggerLevelOptions, onSubmit }) => {
  
  const optionsTemplate = (option) => {
    return (
      <div className="d-flex flex-column">
        <div>{option.label}</div>
        {option.hint ? (
          <FormText className="text-wrap" color="muted">
            {option.hint}
          </FormText>
        ) : null}
      </div>
    );
  };

  const initialValues = useMemo(() => {
    return {
      name: data?.name ?? "",
      description: data?.description ?? "",
      pool_options: data?.pool_options ?? "",
      trigger_level: data?.trigger_level ?? "down",
      tier_1: "",
      tier_2: "",
      tier_3: "",
      tier_4: "",
      tier_5: "",
    };
  }, [data]);

  return (
    <Formik enableReinitialize initialValues={initialValues} onSubmit={onSubmit}>
      {({ values, touched, errors, setFieldValue, handleSubmit }) => {
        return (
          <div className="position-relative">
            <Card className="mb-3">
              <CardBody>
                <Row>
                  <Col md="3">
                    <h3 className="mt-md-2 font-weight-medium">
                      General Configuration
                    </h3>
                  </Col>
                  <Col md="9">
                    <FormItem label="Name">
                      <TritronikInputText name="name" small />
                    </FormItem>
                    <FormItem label="Description">
                      <TritronikInputText name="description" small />
                    </FormItem>
                    <FormItem label="Trigger_level Level">
                      <TritronikSelect
                        name="trigger_level"
                        value={values.trigger_level}
                        options={triggerLevelOptions}
                        onChange={(e) => setFieldValue("trigger_level", e.value)}
                        invalid={touched.trigger_level && errors.trigger_level}
                        error={errors.trigger_level}
                        hint="When to trigger exclusion of a gateway"
                      />
                    </FormItem>
                    <FormItem label="Pool Options">
                      <div className="w-100">
                        <TritronikSelect
                          value={values.pool_options}
                          name="pool_options"
                          options={poolOptions}
                          onChange={(e) => setFieldValue("pool_options", e.value)}
                          invalid={touched.pool_options && errors.pool_options}
                          error={errors.pool_options}
                          itemTemplate={optionsTemplate}
                        />
                        <CollapsibleHelper text="Default: Round Robin, Sticky Address determined by advanced settings">
                          <FormText color="muted">
                            Round Robin: Loops through the translation
                            addresses. Sticky Address: The Sticky Address option
                            can be used with the Round Robin pool type to ensure
                            that a particular source address is always mapped to
                            the same translation address.
                          </FormText>
                        </CollapsibleHelper>
                      </div>
                    </FormItem>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card style={{ marginBottom: "7rem" }}>
              <CardBody>
                <Row>
                  <Col md="3" sm="12">
                    <h3 className="mt-md-2 font-weight-medium">
                      Priority Configuration
                    </h3>
                    <div style={{ width: "80%" }}>
                      <FormText color="muted">
                        Priority determines the order of failover and load
                        balancing. Links with the same priority balance traffic
                        until all are exhausted, after which the next priority
                        level's links are used.
                      </FormText>
                    </div>
                  </Col>
                  <Col md="9" sm="12">
                    <FormItem label="Tier 1">
                      <TritronikSelect
                        value={values.tier_1}
                        name="tier_1"
                        options={gatewaListOptions}
                        onChange={(e) => setFieldValue("tier_1", e.value)}
                        invalid={touched.tier_1 && errors.tier_1}
                        error={errors.tier_1}
                        itemTemplate={optionsTemplate}
                        placeholder="Select Interface"
                      />
                    </FormItem>
                    <FormItem label="Tier 2">
                      <TritronikSelect
                        value={values.tier_2}
                        name="tier_2"
                        options={gatewaListOptions}
                        onChange={(e) => setFieldValue("tier_2", e.value)}
                        invalid={touched.tier_2 && errors.tier_2}
                        error={errors.tier_2}
                        itemTemplate={optionsTemplate}
                        placeholder="Select Interface"
                      />
                    </FormItem>
                    <FormItem label="Tier 3">
                      <TritronikSelect
                        value={values.tier_3}
                        name="tier_3"
                        options={gatewaListOptions}
                        onChange={(e) => setFieldValue("tier_3", e.value)}
                        invalid={touched.tier_3 && errors.tier_3}
                        error={errors.tier_3}
                        itemTemplate={optionsTemplate}
                        placeholder="Select Interface"
                      />
                    </FormItem>
                    <FormItem label="Tier 4">
                      <TritronikSelect
                        value={values.tier_4}
                        name="tier_4"
                        options={gatewaListOptions}
                        onChange={(e) => setFieldValue("tier_4", e.value)}
                        invalid={touched.tier_4 && errors.tier_4}
                        error={errors.tier_4}
                        itemTemplate={optionsTemplate}
                        placeholder="Select Interface"
                      />
                    </FormItem>
                    <FormItem label="Tier 5">
                      <TritronikSelect
                        value={values.tier_5}
                        name="tier_5"
                        options={gatewaListOptions}
                        onChange={(e) => setFieldValue("tier_5", e.value)}
                        invalid={touched.tier_5 && errors.tier_5}
                        error={errors.tier_5}
                        itemTemplate={optionsTemplate}
                        placeholder="Select Interface"
                      />
                    </FormItem>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card
              className="mb-0 position-fixed rounded-0"
              style={{ padding: "0px 30px", bottom: 0, left: 0, right: 0 }}
            >
              <CardBody
                className="d-flex justify-content-end"
                style={{ gap: 8 }}
              >
                <Button
                  color="secondary"
                  size="sm"
                  className="px-5"
                  onClick={history.goBack}
                >
                  Cancel
                </Button>
                <Button color="primary" size="sm" className="px-5" onClick={handleSubmit}>
                  Save
                </Button>
              </CardBody>
            </Card>
          </div>
        );
      }}
    </Formik>
  );
};

GatewayGroupForm.propTypes = {
  data: PropTypes.array,
  gatewaListOptions: PropTypes.array,
  poolOptions: PropTypes.array,
  triggerLevelOptions: PropTypes.array,
}

export default GatewayGroupForm;
